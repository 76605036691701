export const transitions = {
    smoothSpringTransition: {
        type: "spring",
        stiffness: 50, 
        damping: 20, 
        duration: 1.5, 
        mass: 1.5, 
    },
    smoothTweenTransition: {
        type: "tween",
        duration: 2,
        ease: [0.43, 0.13, 0.23, 0.96],
        delay: 0.1, 
    },
    elasticTransition: {
        type: "spring",
        stiffness: 200,
        damping: 15,
        duration: 1.8,
        bounce: 0.5,
    },
    extraSmoothTransition: {
        type: "spring",
        stiffness: 70,
        damping: 30,
        duration: 2.5,
        restSpeed: 0.5,
    },
    acceleratedTransition: {
        type: "tween",
        duration: 2,
        ease: "circOut",
        times: [0, 0.4, 1],
    },
    deceleratedTransition: {
        type: "tween",
        duration: 2.2,
        ease: "circIn",
        times: [0, 0.6, 1],
    },
    combinedTransition: {
        type: "spring",
        stiffness: 100,
        damping: 20,
        duration: 2,
        ease: "easeInOut",
        mass: 1.2,
    },
    dramaticTransition: {
        type: "spring",
        stiffness: 30,
        damping: 8,
        duration: 3,
        mass: 2,
        bounce: 0.8,
    },
    professionalTransition: {
        type: "tween",
        duration: 1.8,
        ease: [0.645, 0.045, 0.355, 1],
        delay: 0.1,
    },
    presentationTransition: {
        type: "spring",
        stiffness: 150,
        damping: 25,
        duration: 2.5,
        mass: 1.8,
        restDelta: 0.01,
    },
};